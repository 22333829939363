import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Checkbox, Form, Input, FormConnect } from '@components/rhf-mui5';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography, Paper } from '@mui/material';
import { suggestionSchema } from '@mikra/validation';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import axios from '../utils/publicAxios';

const SuggestionForm = () => {
  const methods = useForm({
    defaultValues: {},
    mode: 'onBlur',
    resolver: yupResolver(suggestionSchema),
  });
  const { strapiSugestijeIPohvale } = useStaticQuery(query);

  const { enqueueSnackbar } = useSnackbar();

  const { reset } = methods;

  const handleSubmit = async (values) => {
    try {
      const data = {};
      data.email = values.email;
      data.message = values.message;
      data.name = values.name;
      const status = [];
      if (values.jemac) status.push('Jemac/Žirant');
      if (values.korisnikKredita) status.push('Korisnik kredita');
      if (values.suduznik) status.push('Sudužnik');
      const subject = [];
      if (values.prituzba) subject.push('Pritužba');
      if (values.pohvala) subject.push('Pohvala');
      if (values.sugestija) subject.push('Sugestija');
      if (values.molba) subject.push('Molba');
      data.status = status;
      data.subject = subject;
      data.emailList = strapiSugestijeIPohvale?.emailList?.map((item) => item?.email) || [];
      const res = await axios.post('/email/suggestion-mikra', data);

      if (res.status === 200) {
        enqueueSnackbar('Poruka uspješno proslijeđena.', { variant: 'success' });
      }
      reset({ name: '', email: '', message: '' });
    } catch (error) {
      enqueueSnackbar(`Neočekivana greška prilikom slanja poruke: ${error.message}`, { variant: 'error' });
    }
  };

  return (
    <Paper elevation={2}>
      <Box py={[3, 6]} px={[3, 5]} maxWidth={580}>
        <Box mb={[2, 4]}>
          <Typography variant="h5">Online obrazac za Vaše primjedbe, prijedloge i pohvale</Typography>
        </Box>
        <Form methods={methods} onSubmit={handleSubmit} spacing={4}>
          <Input label="Ime i prezime" name="name" />
          <Box my={-1} ml={1}>
            <Typography variant="h6">Status podnosioca prigovora</Typography>
            <FormConnect>
              <Checkbox label="Korisnik kredita" name="korisnikKredita" />
              <Checkbox label="Jemac/Žirant" name="jemac" />
              <Checkbox label="Sudužnik" name="suduznik" />
            </FormConnect>
          </Box>
          <Input label="Vaš Email" name="email" />
          <Box my={-1} ml={1}>
            <Typography variant="h6">Predmet</Typography>
            <FormConnect>
              <Checkbox label="Pritužba" name="prituzba" />
              <Checkbox label="Pohvala" name="pohvala" />
              <Checkbox label="Sugestija" name="sugestija" />
              <Checkbox label="Molba" name="molba" />
            </FormConnect>
          </Box>
          <Input label="Vaša poruka" name="message" multiline rows={6} />
          <Button type="submit" color="primary" variant="contained" fullWidth>
            Proslijedi
          </Button>
        </Form>
        <Box my={2}>
          <Typography variant="subtitle2">{strapiSugestijeIPohvale.formaPravniTekst}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export const query = graphql`
  query SugesstionsQuery {
    strapiSugestijeIPohvale {
      emailList {
        email
      }
      formaPravniTekst
    }
  }
`;

export default SuggestionForm;

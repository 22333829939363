import styled from '@emotion/styled';

const CenteredContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  max-width: ${({ maxWidth }) => maxWidth || 1200}px;
  ${({ theme }) => `
  margin: ${theme.spacing(14)} auto;
  padding: 0 ${theme.spacing(3)};
  ${theme.breakpoints.down('xl')} {
    padding: ${theme.spacing(4)} ${theme.spacing(3)};
    margin: ${theme.spacing(6)} 0;
  }
  ${theme.breakpoints.down('xl')} {
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
  }
  `}
`;

export default CenteredContainer;

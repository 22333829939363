import React from 'react';
import { graphql } from 'gatsby';
import { Box, Typography } from '@mui/material';
import CenteredContainer from '../components/CenteredContainer';
import Layout from '../layout';
import SuggestionForm from '../components/SuggestionForm';
import MarkdownContent from '../components/MarkdownContent';

const PohvalePage = ({ data }) => {
  const { strapiSugestijeIPohvale } = data;
  return (
    <Layout offset={1}>
      <CenteredContainer>
        <Typography variant="overline">{strapiSugestijeIPohvale.podnaslov}</Typography>
        <Typography variant="h2">{strapiSugestijeIPohvale.naslov}</Typography>
        <Box maxWidth={720} my={6}>
          <MarkdownContent children={strapiSugestijeIPohvale.sadrzaj} />
        </Box>
        <SuggestionForm />
      </CenteredContainer>
    </Layout>
  );
};

export const query = graphql`
  query SugestijeQuery {
    strapiSugestijeIPohvale {
      naslov
      podnaslov
      sadrzaj
    }
  }
`;

export default PohvalePage;
